import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AppEmailService from "../../services/appEmailService";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import _ from "../../../../../src/@lodash";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AuthService from "../../../auth/services/authService";
import constants from "../../../helpers/constants";
import * as yup from "yup";

var schema = yup.object().shape({
  emailCategoryId: yup
    .string()
    .required("Email Category is required")
    .notOneOf(["-1"], "Please select a valid option"),
  displayId: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed")
    .required("First name is required"),
  name: yup.string().required("Name name is required"),
  description: yup.string().required("Description name is required"),
  fromDisplayName: yup.string().required("From Display Name is required"),
  subject: yup.string().required("Subject is required"),
  fromEmailId: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required"),
  senderEmailAddress: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required"),
  smtpUsername: yup.string().required("Smtp username name is required"),
  smtpPassword: yup.string().required("Smtp password is required"),
  replyEmailId: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required"),
  smtpServer: yup.string().required("Smtp username name is required"),
  smtpPort: yup.string().required("Smtp Port is required"),
});

var defaultValues = {
  emailCategoryId: "-1",
  displayId: "",
  name: "",
  description: "",
  fromDisplayName: "",
  subject: "",
  fromEmailId: "",
  senderEmailAddress: "",
  smtpUsername: "",
  smtpPassword: "",
  replyEmailId: "",
  smtpServer: "",
  smtpPort: "",
};

function AppEmailDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const userDetail = AuthService.getUser();
  const roles = constants?.roles();
  const editorRef = useRef(null);
  const [emailCategories, setEmailCategories] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailId, setEmailId] = useState(params?.id || 0);
  const [smtpConfigurationId, setSmtpConfigurationId] = useState(0);
  const [isDisableSentEmail, setIsDisableSentEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const [permission, setPermission] = useState(
    userDetail?.permissionIds
      ? userDetail?.permissionIds.split(",").map(Number)
      : []
  );

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
    vertical: "top",
    horizontal: "center",
  });

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const { appOpen, appStatus, appMessage, vertical, horizontal } = state;

  function getEmailCategories() {
    AppEmailService.getEmailCategories()
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmailCategories(response.data);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function getAppEmail(appEmailId) {
    AppEmailService.getAppEmail(appEmailId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmailDetail(response.data);
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function setEmailDetail(value = {}) {
    setValue("emailCategoryId", value?.appEmail?.emailCategoryId || "-1", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("displayId", value?.appEmail?.displayId || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("name", value?.appEmail?.name || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("fromDisplayName", value?.appEmail?.fromDisplayName || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("subject", value?.appEmail?.subject || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("description", value?.appEmail?.description || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    if (window.CKEDITOR.instances[editorRef.current.id]) {
      window.CKEDITOR.instances[editorRef.current.id].setData(
        value?.appEmail?.body
      );
    }
    setSmtpConfigurationId(value?.smtpConfiguration?.smtpConfigurationId);
    setValue("fromEmailId", value?.smtpConfiguration?.fromEmailId || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue(
      "senderEmailAddress",
      value?.smtpConfiguration?.senderEmailAddress || "",
      {
        shouldDirty: false,
        shouldValidate: false,
      }
    );
    setValue("smtpUsername", value?.smtpConfiguration?.smtpUsername || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("smtpPassword", value?.smtpConfiguration?.smtpPassword || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("replyEmailId", value?.smtpConfiguration?.replyEmailId || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("smtpServer", value?.smtpConfiguration?.smtpServer || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    setValue("smtpPort", value?.smtpConfiguration?.smtpPort || "", {
      shouldDirty: false,
      shouldValidate: false,
    });
  }

  function onSubmit(formData) {
    formData = {
      ...formData,
      emailId: emailId,
      smtpConfigurationId: smtpConfigurationId,
      body: window.CKEDITOR.instances[editorRef.current.id].getData(),
    };
    setLoading(true);
    AppEmailService.addUpdateAppEmail(formData)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmailId(response.data);
            getAppEmail(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function sendAppEmail() {
    setIsDisableSentEmail(true);
    AppEmailService.sendAppEmail(email, emailId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setIsDisableSentEmail(false);
            setEmailSend(true);
            setTimeout(() => setEmailSend(false), 2000);
          }
        }
        setIsDisableSentEmail(false);
      })
      .catch((_errors) => {
        setIsDisableSentEmail(false);
        showAppMessage(_errors);
      });
  }

  useEffect(() => {
    if (
      !permission.includes(constants.appEmailEdit) &&
      userDetail?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      getEmailCategories();
      setEmailDetail();
      if (params?.id) {
        getAppEmail(emailId);
      }
      if (window.CKEDITOR && editorRef.current) {
        // Initialize CKEditor on the textarea with source button in the toolbar
        window.CKEDITOR.replace(editorRef.current, {
          extraPlugins: "sourcearea",
          allowedContent: true,
          fullPage: true,
          toolbar: [
            {
              name: "document",
              items: ["-", "Save", "NewPage", "Preview", "-", "Templates"],
            },
            { name: "editing", items: ["Find", "Replace", "-", "SelectAll"] },
            {
              name: "forms",
              items: [
                "Form",
                "Checkbox",
                "Radio",
                "TextField",
                "Textarea",
                "Select",
                "Button",
                "ImageButton",
                "HiddenField",
              ],
            },
            {
              name: "basicstyles",
              items: [
                "Bold",
                "Italic",
                "Underline",
                "Strike",
                "-",
                "Subscript",
                "Superscript",
                "-",
                "RemoveFormat",
              ],
            },
            {
              name: "paragraph",
              items: [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "-",
                "Blockquote",
                "-",
                "JustifyLeft",
                "JustifyCenter",
                "JustifyRight",
                "JustifyBlock",
                "-",
                "BidiLtr",
                "BidiRtl",
              ],
            },
            { name: "links", items: ["Link", "Unlink", "Anchor"] },
            {
              name: "insert",
              items: [
                "Image",
                "Table",
                "HorizontalRule",
                "Smiley",
                "SpecialChar",
                "PageBreak",
              ],
            },
            { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
            { name: "colors", items: ["TextColor", "BGColor"] },
            { name: "tools", items: ["Maximize", "ShowBlocks"] },
            { name: "document", items: ["Source"] },
          ],
        });
      }

      // Cleanup CKEditor instance on component unmount
      return () => {
        if (window.CKEDITOR.instances && window.CKEDITOR.instances.editor1) {
          window.CKEDITOR.instances.editor1.destroy(true);
        }
      };
    }
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }
  return (
    <div>
      <form
        name="loginForm"
        noValidate
        className="flex flex-col justify-center w-full relative"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col lg:flex-row">
          <div className="p-6 max-w-full lg:max-w-[calc(100%_-_400px] w-full lg:h-[calc(100vh_-_80px)] overflow-auto">
            <div className="w-full xl:w-[80%]">
              <div className="xl:flex item-center gap-6 w-full">
                <div className="w-full xl:w-1/2 relative select-menu mb-6">
                  <label className="block">Email Category</label>
                  <Controller
                    className="w-full role-menu"
                    name="emailCategoryId"
                    id="emailCategoryId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="email-select"
                        error={!!errors.emailCategoryId}
                        helperText={errors?.emailCategoryId?.message}
                      >
                        <MenuItem value="-1" selected>
                          Select
                        </MenuItem>
                        {emailCategories.map((option, index) => (
                          <MenuItem key={index} value={option.emailCategoryId}>
                            {option.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
                <div className="w-full xl:w-1/2 relative mb-6">
                  <label className="block">Email ID</label>
                  <Controller
                    name="displayId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        inputProps={{ maxLength: 20 }}
                        autoFocus={true}
                        type="text"
                        error={!!errors.displayId}
                        helperText={errors?.displayId?.message}
                        variant="outlined"
                        placeholder="Enter Email ID"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
              <div className="xl:flex item-center gap-6 w-full">
                <div className="w-full xl:w-1/2 relative mb-6">
                  <label className="block">Email Name</label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        inputProps={{ maxLength: 20 }}
                        autoFocus={true}
                        type="text"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        variant="outlined"
                        placeholder="Enter Email Name"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-full xl:w-1/2 relative mb-6">
                  <label className="block">From Display Name</label>
                  <Controller
                    name="fromDisplayName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        inputProps={{ maxLength: 20 }}
                        autoFocus={true}
                        type="text"
                        rows={4}
                        error={!!errors.fromDisplayName}
                        helperText={errors?.fromDisplayName?.message}
                        variant="outlined"
                        placeholder="Enter From Display Name"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
              <div className="xl:flex item-center gap-6 w-full">
                <div className="w-full xl:w-1/2 relative mb-6">
                  <label className="block">Subject</label>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        inputProps={{ maxLength: 20 }}
                        autoFocus={true}
                        type="text"
                        rows={4}
                        error={!!errors.subject}
                        helperText={errors?.subject?.message}
                        variant="outlined"
                        placeholder="Enter Subject"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-full xl:w-1/2 relative mb-6">
                  <label className="block">Description</label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        inputProps={{ maxLength: 20 }}
                        autoFocus={true}
                        type="text"
                        rows={4}
                        error={!!errors.description}
                        helperText={errors?.description?.message}
                        variant="outlined"
                        placeholder="Enter Description"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="relative mb-6 w-full xl:w-[80%]">
              <div className="flex justify-between">
                <label className="block">Email Body</label>
              </div>
              <textarea ref={editorRef} name="editor1" id="editor1"></textarea>
            </div>
            <div className="mt-6">
              <Button
                variant="contained"
                color="secondary"
                className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out py-4"
                aria-label="Sign in"
                disabled={!isValid || loading}
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
              >
                {(loading && (
                  <PulseLoader
                    color="#000000"
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )) ||
                  "Save"}
              </Button>
            </div>
          </div>
          <div className="px-4 py-6 max-w-full lg:max-w-[400px] border-l w-full lg:h-[calc(100vh_-_80px)] overflow-auto">
            <div className="font-semibold">
              <h3 class="font-semibold mb-4">SMTP Configuration</h3>

              <div className="relative mb-6">
                <label className="block">Display - From Email</label>
                <Controller
                  name="fromEmailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="email"
                      error={!!errors.fromEmailId}
                      helperText={errors?.fromEmailId?.message}
                      variant="outlined"
                      placeholder="Enter From Email address"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">SMTP - Sending Email</label>
                <Controller
                  name="senderEmailAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="email"
                      error={!!errors.senderEmailAddress}
                      helperText={errors?.senderEmailAddress?.message}
                      variant="outlined"
                      placeholder="Enter Sender Email address"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">SMTP - Username</label>
                <Controller
                  name="smtpUsername"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="text"
                      error={!!errors.smtpUsername}
                      helperText={errors?.smtpUsername?.message}
                      variant="outlined"
                      placeholder="Enter Smtp user name"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">SMTP Password</label>
                <Controller
                  name="smtpPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      type={showPassword ? "text" : "password"}
                      error={!!errors.smtpPassword}
                      helperText={errors?.smtpPassword?.message}
                      variant="outlined"
                      placeholder="Enter Smtp Password"
                      required
                      fullWidth
                      inputProps={{ maxLength: 70 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <a
                              className="ps-eye-icon"
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <i class="fi fi-rr-eye-crossed"></i>
                              ) : (
                                <i class="fi fi-rr-eye"></i>
                              )}
                            </a>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">Reply Email</label>
                <Controller
                  name="replyEmailId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="email"
                      error={!!errors.replyEmailId}
                      helperText={errors?.replyEmailId?.message}
                      variant="outlined"
                      placeholder="Enter Reply Email address"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">SMTP SERVER</label>
                <Controller
                  name="smtpServer"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="text"
                      error={!!errors.smtpServer}
                      helperText={errors?.smtpServer?.message}
                      variant="outlined"
                      placeholder="Enter Smtp user name"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              <div className="relative mb-6">
                <label className="block">SMTP Port</label>
                <Controller
                  name="smtpPort"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full p-4"
                      autoFocus={true}
                      type="number"
                      error={!!errors.smtpPort}
                      helperText={errors?.smtpPort?.message}
                      variant="outlined"
                      placeholder="Enter Smtp Port"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                    />
                  )}
                />
              </div>
              {emailId > 0 && (
                <div className="relative mb-6">
                  <label className="block">Send Test Email to</label>
                  <Controller
                    name="testEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="w-full p-4"
                        autoFocus={true}
                        type="email"
                        error={!!errors.testEmail}
                        helperText={errors?.testEmail?.message}
                        variant="outlined"
                        placeholder="Enter From Email address"
                        required
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        onChange={(val) => {
                          setEmail(val?.target?.value);
                        }}
                      />
                    )}
                  />
                  <p className="leading-7 underline">
                    <Link
                      className="text-primaryLime-500"
                      onClick={() => {
                        sendAppEmail();
                      }}
                      disabled={isDisableSentEmail || !email}
                    >
                      Send Email
                    </Link>
                  </p>
                  {emailSend && (
                    <Snackbar
                      open={emailSend}
                      anchorOrigin={{ vertical, horizontal }}
                    >
                      <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        email send successfully!
                      </Alert>
                    </Snackbar>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AppEmailDetail;
