import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AppEmailService from "../../services/appEmailService";
import AuthService from "../../../auth/services/authService";
import constants from "../../../helpers/constants";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from "../../../../../src/@lodash";
import dateFormat, { masks } from "dateformat";

function AppEmailList() {
  const userDetail = AuthService.getUser();
  const navigate = useNavigate();
  const roles = constants?.roles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchTypeID, setSearchTypeID] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [appEmailId, setAppEmailId] = useState();
  const [permission, setPermission] = useState(
    userDetail?.permissionIds
      ? userDetail?.permissionIds.split(",").map(Number)
      : []
  );

  var columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      disableColumnMenu: true,
    },
    {
      field: "displayId",
      headerName: "DisplayID",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "emailCategoryName",
      headerName: "Category Name",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Email Name",
      width: 400,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>{params.value == 1 ? "Active" : "In Active"}</>;
      },
    },
    {
      field: "modifiedDateTime",
      headerName: "Modified",
      width: 250,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value) {
          return <>{dateFormat(params.value, "mm/dd/yyyy h:MM:ss TT")}</>;
        }
      },
    },

    {
      field: "emailId",
      headerName: "Action",
      width: 300,
      disableColumnMenu: true,
      renderCell: (params) => {
        setAppEmailId(params.value);
        return (
          <>
            {permission.includes(constants.appEmailEdit) ||
            userDetail?.roleId === roles.superAdmin ? (
              <>
                <a
                  class="text-primaryLime-500 underline text-base"
                  target={"_blank"}
                  href={"/admin/app-email-detail/" + params.value}
                >
                  Edit
                </a>
              </>
            ) : (
              <></>
            )}
            {(permission.includes(constants.appEmailEdit) &&
              permission.includes(constants.appEmailDelete)) ||
            userDetail?.roleId === roles.superAdmin ? (
              <> | </>
            ) : (
              <></>
            )}

            {permission.includes(constants.appEmailDelete) ||
            userDetail?.roleId === roles.superAdmin ? (
              <>
                <a
                  class="text-primaryLime-500 underline text-base"
                  target={"_blank"}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </a>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });

  const fetchDataWithSortingAndPagination = async (
    sortField,
    sortDirection,
    searchText = ""
  ) => {
    setLoading(true);
    AppEmailService.getAppEmails(
      page + 1,
      pageSize,
      sortField,
      sortDirection,
      searchTypeID,
      searchText
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setRows(response.data);
            setRowCount(response.rowCount);
          } else {
            columns = [];
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  };

  useEffect(() => {
    if (
      !permission.includes(constants.appEmailView) &&
      userDetail?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      fetchDataWithSortingAndPagination("CreationDateTime", "desc");
    }
  }, [page, pageSize]);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function onDeleteAccount() {
    AppEmailService.deleteAppEmail(appEmailId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setShowDeleteModal(false);
            fetchDataWithSortingAndPagination("CreationDateTime", "desc");
          }
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onSearchSubmit({ search }) {
    fetchDataWithSortingAndPagination("CreationDateTime", "desc", search);
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="p-6">
      {permission.includes(constants.appEmailCreate) ||
      userDetail?.roleId === roles.superAdmin ? (
        <form name="settingForm" noValidate className="w-full">
          <div className="flex items-center justify-end">
            <p className="leading-7 text-right mb-4">
              <Link
                className="text-primaryLime-500 underline text-base"
                to={"/admin/app-email-detail"}
                target="_blank"
              >
                +Add Email
              </Link>
            </p>
          </div>
        </form>
      ) : (
        <></>
      )}
      <div className="h-[calc(100vh_-_172px)] data-table">
        <DataGrid
          rows={rows}
          columns={rows.length > 0 ? columns : []}
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          columnVisibilityModel={{
            id: false,
            userId:
              permission.includes(constants.appEmailEdit) ||
              permission.includes(constants.appEmailDelete) ||
              userDetail?.roleId === roles.appEmailAdmin,
          }}
          sortingOrder={["asc", "desc"]}
          onSortModelChange={(newSortModel) => {
            const sortField = newSortModel[0]?.field;
            const sortDirection = newSortModel[0]?.sort;
            fetchDataWithSortingAndPagination(sortField, sortDirection);
          }}
          onPaginationModelChange={(mode) => {
            setPage(mode.page);
            setPageSize(mode.pageSize);
            fetchDataWithSortingAndPagination("CreationDateTime", "desc");
          }}
          loading={loading}
          paginationMode="server"
          pagination
        />
      </div>
      <div>
        <Dialog
          open={showDeleteModal}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' ) {
              return false;
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle
              id="alert-dialog-title"
              class="font-familjen px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 text-[32px]"
            ></DialogTitle> */}
          <DialogContent className="dialog-content small">
            <DialogContentText id="alert-dialog-description">
              <div className="text-center">
                <div className="flex items-center justify-center bg-red-100 w-14 h-14 rounded-12 mx-auto mt-6 mb-3">
                  <i class="fi fi-rr-triangle-warning text-red-500 text-2xl flex items-center"></i>
                </div>
                <div>
                  <div className="text-[32px] font-semibold text-black">
                    Delete your email
                  </div>
                  <div className="text-black text-muted">
                    {" "}
                    Are you sure you want to delete your email?
                    <span className="block">
                      This action cannot be undone, you will loose access to all
                      your data.
                    </span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-actions small">
            <div className="w-full flex items-center justify-center">
              <Button
                variant="contained"
                color="secondary"
                className="border-0 bg-red-500 text-white rounded-xl transition duration-300 ease-in-out shadow-none delete-btn btn-small"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  onDeleteAccount();
                }}
              >
                {(loading && (
                  <PulseLoader
                    color="#000000"
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )) ||
                  "Delete"}
              </Button>
              <Link
                variant="contained"
                color="secondary"
                className="underline ml-6"
                aria-label="Sign in"
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Link>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default AppEmailList;
