import http from "../../config/http-common";

class WelcomeMessageService {

  getWelcomeMessage = (messageId = 0) => {
    return new Promise((resolve, reject) => {
      http.get("Message/GetMessage/" + messageId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  addUpdateWelcomeMessage = (messageId = 0, message) => {
    return new Promise((resolve, reject) => {
      http
        .post("Message/AddUpdateWelcomeMessage", {
          messageId: messageId,
          message: message,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}

const instance = new WelcomeMessageService();

export default instance;
