import { useEffect, useState } from "react";
import authImage from "../../../assets/images/tp-auth.png";
import logo from "../../../assets/images/efile-logo.png";
import * as yup from "yup";
import _ from "../../../../../src/@lodash";
import AuthService from "../../services/authService";
import AdminAuthService from "../../services/adminAuthService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import OtpInput from "react-otp-input";
import constants from "../../../helpers/constants";
import { useNavigate, Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";

var emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultEmailValues = {
  email: "",
};

var secondaryEmailSchema = yup.object().shape({
  secondaryEmail: yup
    .string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required"),
});

var defaultSecondaryEmailValues = {
  secondaryEmail: "",
};

const schemaPwd = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - must be at least 4 chars."),
});

const defaultPwdValues = {
  password: "",
};

function SignIn() {
  const signInSteps = constants?.signInSteps();
  const [signInStep, setSignInStep] = useState(signInSteps.signIn);
  const preferenceTypeIDs = constants?.signInPreferenceTypeIDs();
  const [signInPreferenceTypeID, setSignInPreferenceTypeID] = useState(
    preferenceTypeIDs.signUp
  );

  const [otp, setOtp] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const [resendOTPSeconds, setResendOTPSeconds] = useState(
    constants.ResendOTPSeconds
  );

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  const navigate = useNavigate();

  var defaultValues =
    signInStep === signInSteps.signInWithPassword
      ? defaultPwdValues
      : signInStep === signInSteps.signInWithRecovery
      ? defaultSecondaryEmailValues
      : defaultEmailValues;
  var schema =
    signInStep === signInSteps.signInWithPassword
      ? schemaPwd
      : signInStep === signInSteps.signInWithRecovery
      ? secondaryEmailSchema
      : emailSchema;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate("/admin/dashboard");
    }
    else{
      navigate("/admin/sign-in");
    }
    if (signInStep === signInSteps.signInWithPassword) {
      setValue("password", "", { shouldDirty: false, shouldValidate: false });
    } else setValue("email", "", { shouldDirty: false, shouldValidate: false });
  }, [setValue]);

  function onSubmit({ email }) {
    setLoading(true);
    setEmailAddress(email);
    AdminAuthService.doesEmailAddressExists(email)
      .then((response) => {
        setLoading(false);
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data?.isEmailExists) {
            setSignInPreferenceTypeID(response.data.signInpreferenceTypeID);
            if (
              response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPassword ||
              response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPasswordAndNoPreference
            ) {
              setValue("password", "", {
                shouldDirty: false,
                shouldValidate: false,
              });
              if (
                response.data?.signInpreferenceTypeID ===
                preferenceTypeIDs.signInWihPasswordAndNoPreference
              )
                setSignInStep(signInSteps.signInNext);
              else setSignInStep(signInSteps.signInWithPassword);
            } else {
              signInWithOTP(email);
            }
          }
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
        // _errors.forEach((error) => {
        //     setError(error.type, {
        //         type: 'manual',
        //         message: error.message,
        //     });
        // });
      });
  }

  function signInWithOTP(email = "") {
    setLoading(true);
    AdminAuthService.signInWithOTP(email ? email : emailAddress)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
            setOtp("");
            setSignInStep(signInSteps.signInWithOTP);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onReSendOTP() {
    setLoading(true);
    AdminAuthService.reSendOTP(emailAddress, true)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response?.data) {
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  async function onSignIn() {
    setLoading(true);
    if (signInStep === signInSteps.signInWithOTP) {
      await AdminAuthService.signIn(emailAddress, true, otp)
        .then((response) => {
          showAppMessage(response);
          setLoading(false);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/admin/dashboard");
              navigate(0)
            }
          }
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function onSignInWithPassword({ password }) {
    setLoading(true);
    if (signInStep === signInSteps.signInWithPassword) {
      AdminAuthService.signIn(emailAddress, false, password)
        .then((response) => {
          showAppMessage(response);
          if (response?.statusCode === 200) {
            if (response?.data) {
              navigate("/admin/dashboard");
              navigate(0)
            }
          }
          setLoading(false);
        })
        .catch((_errors) => {
          setLoading(false);
          showAppMessage(_errors);
        });
    }
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function showHideOTPMessage(response) {
    setShowOtpMessage(true);
    setTimeout(() => {
      setShowOtpMessage(false);
    }, 3000);
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function showResendOTPSeconds() {
    var intervalId = setInterval(() => {
      setResendOTPSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return "00";
        }
        return prev < 11 ? `0${prev - 1}` : prev - 1;
      });
    }, constants.ResendOTPCount);
  }

  return (
    <div className="bg-efileBlue">
      <div className="h-screen overflow-auto flex">
        <div className="container mx-auto">
          <div className="w-full z-10 px-4 md:px-10 xl:px-0 inline-grid xl:flex items-center justify-center h-full">
            <div className="w-full flex flex-col xl:flex-row relative items-center justify-center">
              <div className="w-full xl:w-2/4 text-center mt-10 md:mt-8 xl:mt-0 h-full">
                <img
                  className="mx-auto block xl:hidden mb-4 w-32"
                  src={logo}
                  alt="auth image"
                />
                <img
                  className="mx-auto w-72 sm:w-auto"
                  src={authImage}
                  alt="auth image"
                />
                <h1 className="auth-heading font-semibold mt-2 sm:mt-4 text-white">
                  {" "}
                  <span className="font-normal text-xl">
                    Get Your Taxes Done
                  </span>
                  <br /> With or By A Taxpert<sup>&reg;</sup>
                </h1>
                <p className="text-white">
                  Plan, Prepare, File Your Taxes. Do-It-Yourself or In a Tax
                  Team.
                </p>
              </div>
              <div className="w-full xl:w-2/4 flex justify-center mt-8 xl:mt-0 mb-10 md:mb-8 xl:mb-0">
                <div className="sm:w-[600px] w-full relative">
                  <img
                    className="mx-auto hidden xl:block w-32"
                    src={logo}
                    alt="auth image"
                  />
                  <div className="bg-white w-full rounded-xl mt-0 md:mt-12 relative auth-box p-6 sm:p-8 md:p-12 lg:p-[50px]">
                    {(signInStep === signInSteps.signIn && (
                      <div>
                        <h1 className="text-center mb-6 sm:mb-8 md:mb-12 lg:mb-[50px]">
                          Welcome
                        </h1>
                        <form
                          name="loginForm"
                          noValidate
                          className="flex flex-col justify-center w-full"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="reative mb-[22px] lg:mb-[30px]">
                            <label className="mb-2">Email Address</label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="w-full p-4"
                                  autoFocus={true}
                                  type="email"
                                  error={!!errors.email}
                                  helperText={errors?.email?.message}
                                  variant="outlined"
                                  placeholder="Enter Email address"
                                  required
                                  fullWidth
                                  inputProps={{ maxLength: 40 }}
                                />
                              )}
                            />
                            <div className="absolute tp-message">
                              <AppMessage
                                status={appStatus}
                                message={appMessage}
                                vertical="top"
                                horizontal="center"
                                isOpen={appOpen}
                                timeOut={100}
                              ></AppMessage>
                            </div>
                          </div>
                          {/* <p className="my-[22px] leading-7">
                            Note: We will verify your account status. If none
                            exists, one will be created, We will email you a sign-in code or OTP (one-time password).{" "}
                            <Tooltip title="Under Settings you can create a password and select to sign in with that password in the future. Your old eFile username is no longer required to sign in. ">
                                  <span>
                                    <a className="text-primaryLime-500">
                                      Details
                                    </a>
                                  </span>
                                </Tooltip>
                          </p> */}
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                            aria-label="Sign in"
                            sx={{ textTransform: "capitalize" }}
                            disabled={
                              _.isEmpty(dirtyFields) || !isValid || loading
                            }
                            type="submit"
                            size="large"
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Next"}
                          </Button>
                        </form>
                      </div>
                    )) ||
                      (signInStep === signInSteps.signInNext && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                            onClick={() => {
                              setSignInStep(signInSteps.signIn);
                            }}
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <div>
                            <h1 className="text-center">
                              Sign In to Your Account
                            </h1>
                            <p className="leading-7 text-center mt-3.5">
                              Select your Sign in method for{" "}
                              <span className="font-semibold">
                                {emailAddress}
                              </span>
                            </p>
                            <div
                              className={
                                signInPreferenceTypeID ===
                                preferenceTypeIDs.signInWihPasswordAndNoPreference
                                  ? "flex gap-6 mt-[50px]"
                                  : "mt-[50px] w-full"
                              }
                            >
                              {signInPreferenceTypeID !==
                                preferenceTypeIDs.signInWihPassword && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label="Sign in with OTP"
                                  type="submit"
                                  size="large"
                                  disabled={loading}
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={() => signInWithOTP()}
                                >
                                  {(loading && (
                                    <PulseLoader
                                      color="#000000"
                                      loading={loading}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  )) ||
                                    "Sign in with OTP"}
                                </Button>
                              )}
                              {(signInPreferenceTypeID ===
                                preferenceTypeIDs.signInWihPassword ||
                                signInPreferenceTypeID ===
                                  preferenceTypeIDs.signInWihPasswordAndNoPreference) && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                  aria-label="Sign in with Password"
                                  type="submit"
                                  size="large"
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setValue("password", "", {
                                      shouldDirty: false,
                                      shouldValidate: false,
                                    });
                                    setSignInStep(
                                      signInSteps.signInWithPassword
                                    );
                                  }}
                                >
                                  Sign in with Password
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )) ||
                      (signInStep === signInSteps.signInWithOTP && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                            onClick={() => {
                              setSignInStep(
                                signInPreferenceTypeID ===
                                  preferenceTypeIDs.signInWihPasswordAndNoPreference
                                  ? signInSteps.signInNext
                                  : signInSteps.signIn
                              );
                            }}
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">Sign in with OTP</h1>
                          <p className="leading-7 text-center mt-3.5">
                          Enter the sign-in code or OTP sent to{" "}
                            <span className="font-semibold">
                              {emailAddress}
                            </span>
                          </p>
                          <div className="mt-[50px]">
                            <div className="relative">
                              <label>Enter OTP</label>
                              <div className="otp-input mt-2">
                                <OtpInput
                                  className="w-full p-4"
                                  inputStyle="inputStyle"
                                  value={otp}
                                  inputType="number"
                                  onChange={setOtp}
                                  numInputs={6}
                                  placeholder="------"
                                  shouldAutoFocus={true}
                                  required
                                  renderInput={(props) => <input {...props} />}
                                />
                              </div>
                              <div className="absolute tp-message">
                                <AppMessage
                                  status={appStatus}
                                  message={appMessage}
                                  vertical="top"
                                  horizontal="center"
                                  isOpen={appOpen}
                                  timeOut={100}
                                ></AppMessage>
                              </div>
                            </div>
                            <div class="flex mt-4 mb-[22px] text-sm text-black justify-end items-center">
                              <p className="text-right">
                                Didn't get OTP?{" "}
                                <a
                                  disabled={loading || resendOTPSeconds > 0}
                                  href="javascript:void(0);"
                                  onClick={() => onReSendOTP()}
                                  className="text-primaryLime-500"
                                >
                                  Resend OTP{" "}
                                </a>
                                {resendOTPSeconds > 0 && (
                                  <span className="text-primaryLime-500">
                                    (00:{resendOTPSeconds}
                                    {constants.ResendOTPPreference})
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                            aria-label="Sign in"
                            type="submit"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => onSignIn()}
                            size="large"
                            disabled={
                              (otp.length !== 6 && true) || false || loading
                            }
                          >
                            {(loading && (
                              <PulseLoader
                                color="#000000"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            )) ||
                              "Sign in"}
                          </Button>
                        </div>
                      )) ||
                      (signInStep === signInSteps.signInWithPassword && (
                        <div>
                          <button
                            className="absolute top-2 md:top-4 lg:top-6 left-2 md:left-4 lg:left-6 capitalize"
                            onClick={() => {
                              setSignInStep(
                                signInPreferenceTypeID ===
                                  preferenceTypeIDs.signInWihPasswordAndNoPreference
                                  ? signInSteps.signInNext
                                  : signInSteps.signIn
                              );
                            }}
                          >
                            <i className="fi fi-rr-arrow-small-left text-[24px]"></i>
                          </button>
                          <h1 className="text-center">Sign in with Password</h1>
                          <p className="leading-7 text-center mt-3.5">
                            Enter your password for{" "}
                            <span className="font-semibold">
                              {" "}
                              {emailAddress}{" "}
                            </span>{" "}
                            to continue.
                          </p>
                          <div className="mt-[50px]">
                            <form
                              name="loginForm"
                              noValidate
                              className="flex flex-col justify-center w-full"
                              onSubmit={handleSubmit(onSignInWithPassword)}
                            >
                              <div>
                                <div className="relative">
                                  <label className="mb-2">Password</label>
                                  <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        variant="outlined"
                                        placeholder="Enter Password"
                                        required
                                        fullWidth
                                        autoFocus={true}
                                        inputProps={{ maxLength: 14 }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <a
                                                className="ps-eye-icon"
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                  setShowPassword(
                                                    !showPassword
                                                  );
                                                }}
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <i class="fi fi-rr-eye-crossed"></i>
                                                ) : (
                                                  <i class="fi fi-rr-eye"></i>
                                                )}
                                              </a>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                  <div className="absolute tp-message">
                                    <AppMessage
                                      status={appStatus}
                                      message={appMessage}
                                      vertical="top"
                                      horizontal="center"
                                      isOpen={appOpen}
                                      timeOut={100}
                                    ></AppMessage>
                                  </div>
                                </div>

                                <div className="mt-4 mb-[22px]">
                                  <p className="text-right ml-auto text-sm">
                                    <Link
                                      to="/admin/forgot-password"
                                      state={{ email: emailAddress }}
                                      className="text-primaryLime-500"
                                    >
                                      Forgot Password?
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                                aria-label="Sign in"
                                type="submit"
                                size="large"
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  _.isEmpty(dirtyFields) || !isValid || loading
                                }
                              >
                                {(loading && (
                                  <PulseLoader
                                    color="#000000"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                )) ||
                                  "Sign in"}
                              </Button>
                            </form>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignIn;
