import { useEffect, useState, useRef } from "react";
import errorImage from "../app/assets/images/404-image.png";
import logo from "../app/assets/images/efile-logo.png";
import AuthService from "../app/auth/services/authService";
import constants from "../../src/app/helpers/constants";
// ../assets/images/404-image.png

function ErrorPage() {
  const userDetail = AuthService.getUser();
  const roles = constants?.roles();
  return (
    <>
      <div>
        <div className="flex items-center justify-center bg-efileBlue">
          <img
            className="mx-auto my-4 sm:px-0 w-32"
            src={logo}
            alt="taxpert logo"
          />
        </div>
        <img
          className="mx-auto mt-6 md:mt-8 lg:mt-[50px] mb-6 md:mb-8 px-6 sm:px-0 w-full sm:w-1/2 md:w-1/4"
          src={errorImage}
          alt="404 image"
        />
        <h2 className="text-center text-4xl mb-2">Page not found</h2>
        <p className="mb-8 lg:mb-[50px] text-center">
          You can search for the page you want hear or return to the Homepage.
        </p>
        <div className="flex items-center justify-center">
          <a
            className="py-2 px-4 bg-gray-100 rounded-lg inline-flex items-center"
            href={
              userDetail?.roleId === roles.admin ||
              userDetail?.roleId === roles.superAdmin
                ? "/admin/dashboard"
                : "/dashboard"
            }
          >
            <i class="fi fi-rr-arrow-small-left inline-flex items-center mr-2"></i>{" "}
            Back to Home
          </a>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
