import http from "../../config/http-common";

class TaxReturnService {
    setTaxReturnFileView = (taxreturnFileId) => {
        return new Promise((resolve, reject) => {
          http
            .put(
              "TaxReturn/SetTaxReturnFileView/" + taxreturnFileId
            )
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            });
        });
      };

      getTaxReturnFile = (taxreturnFileId) => {
        return new Promise((resolve, reject) => {
          http
            .get(
              "TaxReturn/GetTaxReturnFile/" + taxreturnFileId,{ responseType: 'blob' }
            )
            .then((response) => {
              if (response.data) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            });
        });
      };
}

const instance = new TaxReturnService();

export default instance;
