import http from "../../config/http-common";

class UserService {
  updateUser = (user, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/Update/" + user.firstName + "/" + user.lastName + "/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateEmailAddress = (emailAddress, emailAddressID, isRecoveryEmail) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/AddUpdateEmailAddress/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            isRecoveryEmail
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyEmailOtp = (emailAddress, emailAddressID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdatePhoneNumber = (
    phoneNumber,
    phoneNumberID,
    isRecoveryPhoneNumber
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "User/AddUpdatePhoneNumber/" +
            phoneNumber +
            "/" +
            phoneNumberID +
            "/" +
            isRecoveryPhoneNumber
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyPhoneOtp = (phone, phoneNumberID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyPhoneNumberVerificationCode/" +
            phone +
            "/" +
            phoneNumberID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updatePassword = (password, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdatePassword/" + password + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  resetPassword = (password, newPassword, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/ResetPassword/" + password + "/" + newPassword + "/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryPhone = (phone, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryPhoneNumberVerificationCode/" + phone + "/" + otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryEmail = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyRecoveryEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendEmailOTP = (emailAddress, isRecoveryEmail = false) => {
    return new Promise((resolve, reject) => {
      http
        .get("User/ReSendEmailOTP/" + emailAddress + "/" + isRecoveryEmail)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendPhoneOTP = (phone, isSecondaryPhone = false) => {
    return new Promise((resolve, reject) => {
      http
        .get("User/ReSendPhoneOTP/" + phone + "/" + isSecondaryPhone)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateSignInPreference = (signInpreferenceTypeId, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/UpdateSignInPreference/" + signInpreferenceTypeId + "/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryPhoneNumber = (phoneNumberID, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/SetPrimaryPhoneNumber/" + phoneNumberID + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryEmailAddress = (emailAddressID, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/SetPrimaryEmailAddress/" + emailAddressID + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  deleteAccount = (userId = 0, accountID = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/DeleteAccount/" + userId + "/" + accountID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateSecondaryEmailCount = (count) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateSecondaryEmailCount" + "/" + count)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getUsers = (
    phoneNumber = 1,
    pageSize = 10,
    sortColumn = "CreationDateTime",
    sortOrder = "desc",
    searchTypeID = 0,
    searchTerm = ""
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/GetUsers/" +
            phoneNumber +
            "/" +
            pageSize +
            "/" +
            sortColumn +
            "/" +
            sortOrder +
            "/" +
            searchTypeID +
            "/" +
            searchTerm
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getRoles = () => {
    return new Promise((resolve, reject) => {
      http.get("User/GetRoles").then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  getUser = (userID) => {
    return new Promise((resolve, reject) => {
      http.get("User/GetUser/" + userID).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  getTaxpayers = (taxYear = 1, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .get("User/GetTaxpayers/" + taxYear + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getFileOTP = (userId = 0) => {
    return new Promise((resolve, reject) => {
      http.put("User/GetFileOTP/" + userId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  verifyFileVerificationCode = (verificationCode, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/VerifyFileVerificationCode/" + verificationCode + "/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendFileOTP = (userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "User/ReSendFileOTP/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateTaxReturnClick = ( userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("User/UpdateTaxReturnClick/"+ userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}

const instance = new UserService();

export default instance;
