import http from "../../config/http-common";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

class AuthService {
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  doesEmailAddressExists = (email, invitationCode = "") => {
    return new Promise((resolve, reject) => {
      http
        .post("Account/DoesEmailAddressExists", {
          emailAddress: email,
          invitationCode: invitationCode,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  signInWithOTP = (email, isForgotPassword = false) => {
    return new Promise((resolve, reject) => {
      http
        .post("Account/SignInWithOTP", {
          emailAddress: email,
          isForgotPassword: isForgotPassword,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  signUp = (email) => {
    return new Promise((resolve, reject) => {
      http.post("Account/SignUp/", email).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  verifySignUp = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .post("Account/VerifySignUp", {
          emailAddress: emailAddress,
          verificationCode: otp,
        })
        .then((response) => {
          if (response.data) {
            if (response?.data?.data) {
              localStorage.setItem("authenticated", true);
              this.setSession(response.data.data?.token);
              this.setUser(response.data.data);
              resolve(response.data);
            } else {
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyForgotPassword = (emailAddress, isEmail, password) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "Account/VerifyForgotPassword/" +
            emailAddress +
            "/" +
            isEmail +
            "/" +
            password
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  onForgotNewPassword = (emailAddress, isEmail, password) => {
    return new Promise((resolve, reject) => {
      http
        .post("Account/ForgotNewPassword", {
          emailAddress: emailAddress,
          isEmail: isEmail,
          password: password,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendOTP = (email, isSignIn, isForgotPassword = false) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "Account/ReSendOTP/" + email + "/" + isSignIn + "/" + isForgotPassword
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  async signIn(emailAddress, isOTP, password) {
    return await new Promise((resolve, reject) => {
      http
        .post("Account/SignIn", {
          emailAddress: emailAddress,
          isOTP: isOTP,
          password: password,
        })
        .then((response) => {
          if (response.data) {
            //resolve(response.data);
            if (response?.data?.data) {
              localStorage.setItem("authenticated", true);
              this.setSession(response.data.data?.token);
              this.setUser(response.data.data);
              this.isAuthenticated();
              resolve(response.data);
            } else {
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        });
    });
  }

  setSession = (access_token) => {
    if (access_token) {
      this.setInterceptors();
      localStorage.setItem("access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setUser = (user) => {
    if (user) {
      user.token = "";
      localStorage.setItem("user", btoa(JSON.stringify(user)));
    } else {
      localStorage.removeItem("user");
    }
  };

  getUser = () => {
    var user = window.localStorage.getItem("user");
    if (user != null) {
      user = atob(user);
      return JSON.parse(user);
    } else return user;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("access_token");
  };

  setError = (error) => {
    //this.emit("onError", error?.response?.data?.message);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
    } else {
      this.setSession(null);
    }
  };

  isAuthenticated = () => {
    const access_token = this.getAccessToken();
    if (
      JSON.parse(localStorage.getItem("authenticated")) !== null &&
      JSON.parse(localStorage.getItem("authenticated")) !== "" &&
      this.isAuthTokenValid(access_token)
    ) {
      return true;
    } else {
      return false;
    }
  };

  logout = () => {
    localStorage.clear();
  };
}
const instance = new AuthService();

export default instance;
