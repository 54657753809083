import { useEffect, useState, useRef } from "react";
import constants from "../../../helpers/constants";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PulseLoader from "react-spinners/PulseLoader";
import AppMessage from "../../../main/app-message/message";
import _ from  "../../../../../src/@lodash";
// import UserService from "../../services/userService";
import AdminService from "../../services/adminService";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import InputAdornment from "@mui/material/InputAdornment";
import successfullyMark from "../../../assets/images/success-mark.png";
import Emitter from "../../services/eventEmitter";
import * as yup from "yup";

var displaySchema = yup.object().shape({
  phone: yup
    .string()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (value) {
        return isValidPhoneNumber("+" + value);
      }
      return false;
    })
    .required("Phone number is required"),
});

var defaultDisplayValues = {
  phone: "",
};

var phoneSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (value) {
        return isValidPhoneNumber("+" + value);
      }
      return false;
    })
    .required("Phone number is required"),
});

var defaultPhoneValues = {
  phoneNumber: "",
};

function SettingPhone({handleOnGetUser}) {
  const [userDetail, setUserDetail] = useState({});
  const settingSteps = constants?.signUpSteps();
  const [phoneSteps, setPhoneSteps] = useState(settingSteps.signUp);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState(userDetail?.phoneNumbers?.phoneNumber);
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const phoneFieldRef = useRef(null);

  var schema = showModal ? phoneSchema : displaySchema;
  var defaultValues = showModal ? defaultPhoneValues : defaultDisplayValues;

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const setProfile = () => {
    setValue("phoneNumber", userDetail?.phoneNumbers?.phoneNumber || "+1", {
      shouldDirty: false,
      shouldValidate: false,
    });
  };

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    Emitter.on("user_detail", (value) => {
      if (value) {
        setUserDetail(value)
        setValue("phone", value?.phoneNumbers?.phoneNumber || "", {
          shouldDirty: false,
          shouldValidate: false,
        });
        setPhone(value?.phoneNumbers?.phoneNumber);
      }
    });
    // return () => {
    //   Emitter.off("user_detail");
    // };
  }, [setValue]);

  function onSubmit({ phoneNumber }) {
    setLoading(true);
    setPhone(phoneNumber);
    AdminService.addUpdateAdmnUserPhoneNumber(
      phoneNumber,
      userDetail?.phoneNumbers?.phoneNumberId || 0,
      false,
      userDetail?.users?.userId,
      userDetail?.users?.accountId
    )
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setPhoneSteps(settingSteps.signUpSuccess);
            handleOnGetUser();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div>
      <div>
        {userDetail?.phoneNumbers?.phoneNumber ? (
          <form
            name="settingForm"
            noValidate
            className="flex flex-col justify-center w-full"
          >
            <div>
              <label className="mb-2">Phone Number</label>
              <div className="relative">
                <div className="relative">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"us"}
                        value={phone}
                        className="phone-number-input w-full transition duration-300 ease-in-out"
                        required
                        disabled
                        InputProps={{
                          name: "phone",
                          id: "phone",
                        }}
                      />
                    )}
                  />
                  <InputAdornment
                    position="end"
                    className="absolute top-0 right-3.5 h-full phone-input-check"
                  >
                    <Link
                      className="text-primaryLime-500 leading-7 text-right underline mr-2.5"
                      onClick={() => {
                        setProfile();
                        setPhoneSteps(settingSteps.signUp);
                        setShowModal(true);
                        setTimeout(() => {
                          const inputElement =
                            phoneFieldRef.current.querySelector("input");
                          if (inputElement) {
                            inputElement.focus();
                          }
                        }, 5);
                      }}
                    >
                      Change
                    </Link>
                    <a
                      className="field-verify text-xl text-green-500"
                      aria-label="email verify"
                      edge="end"
                    >
                      <i class="fi fi-ss-check-circle flex check-icon"></i>
                    </a>
                  </InputAdornment>
                </div>
                  {!userDetail?.recoveryPhoneNumbers?.phoneNumber && (
                    <p className="leading-7 text-right absolute -bottom-[26px] right-0"><span>&nbsp;</span>
                        <Link
                          className="text-primaryLime-500 underline"
                          onClick={() => {
                            Emitter.emit("show_recovery_phone_modal", true);
                          }}
                        >
                          +Add Secondary Phone Number
                        </Link>
                    </p>
                  )}
              </div>
            </div>
          </form>
        ) : (
          <div class="mt-6">
            <Link
              className="text-primaryLime-500 mt-6 lg:mt-0 leading-7 underline"
              onClick={() => {
                setPhoneSteps(settingSteps.signUp);
                setShowModal(true);
                setTimeout(() => {
                  setProfile();
                  const inputElement =
                    phoneFieldRef.current.querySelector("input");
                  if (inputElement) {
                    inputElement.focus();
                  }
                }, 5);
              }}
            >
              +Add Phone Number
            </Link>
          </div>
        )}
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' ) {
              return false;
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {(phoneSteps === settingSteps.signUp && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
              >
                {userDetail?.phoneNumbers?.phoneNumber
                  ? "Change Phone Number"
                  : "Add Phone Number"}
              </DialogTitle>
              <DialogContent className="dialog-content">
                <DialogContentText id="alert-dialog-description">
                  <div>
                    <p className="text-center text-black">
                      Enter your new Phone Number below. <br /> OTP will be send
                      this address to confirm the change.
                    </p>
                    <div className="mt-[50px] relative" ref={phoneFieldRef}>
                      <label className="mb-2">Phone Number</label>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        id="phoneNumber"
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={"us"}
                            // value={null}
                            error={!!errors.phoneNumber}
                            helperText={errors?.phoneNumber?.message}
                            className="phone-number-input w-full transition duration-300 ease-in-out"
                            required
                            inputProps={{
                              name: "phoneNumber",
                              id: "phoneNumber",
                              autoFocus: true,
                            }}
                          />
                        )}
                      />
                      <div className="absolute tp-message">
                        <div className="error-msg">
                          {errors?.phoneNumber?.message && (
                            <span>{errors?.phoneNumber?.message} </span>
                          )}
                        </div>
                        <AppMessage
                          status={appStatus}
                          message={appMessage}
                          vertical="top"
                          horizontal="center"
                          isOpen={appOpen}
                          timeOut={100}
                        ></AppMessage>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                    size="large"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )) ||
            (phoneSteps === settingSteps.signUpSuccess && (
              <div>
                {/* <a
                  className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fi fi-rr-circle-xmark"></i>{" "}
                </a> */}
                <DialogTitle
                  id="alert-dialog-title"
                  class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
                >
                  <span>
                    <img
                      className="mx-auto block mb-4 w-[50px]"
                      src={successfullyMark}
                      alt="successfully"
                    />
                  </span>
                  Phone Number Set!
                </DialogTitle>
                <DialogContent className="dialog-content">
                  <DialogContentText id="alert-dialog-description">
                    <p className="text-center text-black">
                      Your phone number has been updated to{" "}
                      <span className="font-semibold">{phone}</span>
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-actions">
                  <div className="w-full">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-full primary-btn bg-primary-500 hover:bg-primary-600 w-full rounded-xl transition duration-300 ease-in-out"
                      aria-label="Sign in"
                      type="submit"
                      size="large"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => setShowModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))}
        </Dialog>
      </div>
    </div>
  );
}
export default SettingPhone;
