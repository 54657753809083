import http from "../../config/http-common";

class AppEmailService {
  getAppEmails = (
    phoneNumber = 1,
    pageSize = 10,
    sortColumn = "CreationDateTime",
    sortOrder = "desc",
    searchTypeID = 0,
    searchTerm = ""
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AppEmail/GetAppEmails/" +
            phoneNumber +
            "/" +
            pageSize +
            "/" +
            sortColumn +
            "/" +
            sortOrder +
            "/" +
            searchTypeID +
            "/" +
            searchTerm
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  deleteAppEmail = (emailId) => {
    return new Promise((resolve, reject) => {
      http.put("AppEmail/DeleteAppEmail/" + emailId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  getEmailCategories = () => {
    return new Promise((resolve, reject) => {
      http.get("AppEmail/GetEmailCategories").then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  getAppEmail = (emailId) => {
    return new Promise((resolve, reject) => {
      http.get("AppEmail/GetAppEmail/" + emailId).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  addUpdateAppEmail = (formData) => {
    return new Promise((resolve, reject) => {
      http.post("AppEmail/AddUpdateAppEmail", formData).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  sendAppEmail = (email, emailId) => {
    return new Promise((resolve, reject) => {
      http
        .post("AppEmail/SendAppEmail/" + email + "/" + emailId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}

const instance = new AppEmailService();

export default instance;
