import http from "../../config/http-common";

class AdminService {
  updateUser = (user, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/Update/" +
            user.firstName +
            "/" +
            user.lastName +
            "/" +
            userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updateSignInPreference = (signInpreferenceTypeId, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/UpdateSignInPreference/" +
            signInpreferenceTypeId +
            "/" +
            userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  deleteAccount = (userId = 0, accountID = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("AdminUser/DeleteAccount/" + userId + "/" + accountID)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateAdminPhoneNumber = (
    phoneNumber,
    phoneNumberID,
    isRecoveryPhoneNumber
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "AdminUser/AddUpdatePhoneNumber/" +
            phoneNumber +
            "/" +
            phoneNumberID +
            "/" +
            isRecoveryPhoneNumber
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyPhoneOtp = (phone, phoneNumberID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminUser/VerifyPhoneNumberVerificationCode/" +
            phone +
            "/" +
            phoneNumberID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyRecoveryPhone = (phone, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminUser/VerifyRecoveryPhoneNumberVerificationCode/" +
            phone +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  updatePassword = (password, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("AdminUser/UpdatePassword/" + password + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  resetPassword = (password, newPassword, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/ResetPassword/" +
            password +
            "/" +
            newPassword +
            "/" +
            userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendEmailOTP = (emailAddress, isRecoveryEmail = false) => {
    return new Promise((resolve, reject) => {
      http
        .get("AdminUser/ReSendEmailOTP/" + emailAddress + "/" + isRecoveryEmail)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendPhoneOTP = (phone, isSecondaryPhone = false) => {
    return new Promise((resolve, reject) => {
      http
        .get("AdminUser/ReSendPhoneOTP/" + phone + "/" + isSecondaryPhone)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateAdminEmailAddress = (
    emailAddress,
    emailAddressID,
    isRecoveryEmail
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/AddUpdateEmailAddress/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            isRecoveryEmail
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  verifyEmailOtp = (emailAddress, emailAddressID, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminUser/VerifyEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryPhoneNumber = (phoneNumberID, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put("AdminUser/SetPrimaryPhoneNumber/" + phoneNumberID + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  setPrimaryEmailAddress = (emailAddressID, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/SetPrimaryEmailAddress/" + emailAddressID + "/" + userId
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getUsers = (
    phoneNumber = 1,
    pageSize = 10,
    sortColumn = "CreationDateTime",
    sortOrder = "desc",
    searchTypeID = 0,
    searchTerm = ""
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminUser/GetUsers/" +
            phoneNumber +
            "/" +
            pageSize +
            "/" +
            sortColumn +
            "/" +
            sortOrder +
            "/" +
            searchTypeID +
            "/" +
            searchTerm
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getUser = (userID) => {
    return new Promise((resolve, reject) => {
      http.get("AdminUser/GetUser/" + userID).then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      });
    });
  };

  verifyRecoveryEmail = (emailAddress, otp) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminUser/VerifyRecoveryEmailAddressVerificationCode/" +
            emailAddress +
            "/" +
            otp
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateAdmnUserEmailAddress = (
    emailAddress,
    emailAddressID,
    isRecoveryEmail,
    userId,
    accountID
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "AdminUser/AddUpdateEmailAddress/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            isRecoveryEmail +
            "/" +
            userId +
            "/" +
            accountID
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateAdmnUserPhoneNumber = (
    phoneNumber,
    phoneNumberID,
    isRecoveryPhoneNumber,
    userId,
    accountID
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "AdminUser/AddUpdatePhoneNumber/" +
            phoneNumber +
            "/" +
            phoneNumberID +
            "/" +
            isRecoveryPhoneNumber +
            "/" +
            userId +
            "/" +
            accountID
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getTaxpayers = (taxYear = 1, userId = 0) => {
    return new Promise((resolve, reject) => {
      http
        .get("AdminUser/GetTaxpayers/" + taxYear + "/" + userId)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdateEmailAddress = (
    emailAddress,
    emailAddressID,
    isRecoveryEmail,
    userId,
    accountID
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(
          "User/AddUpdateEmailAddress/" +
            emailAddress +
            "/" +
            emailAddressID +
            "/" +
            isRecoveryEmail +
            "/" +
            userId +
            "/" +
            accountID
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  addUpdatePhoneNumber = (
    phoneNumber,
    phoneNumberID,
    isRecoveryPhoneNumber,
    userId,
    accountID
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "User/AddUpdatePhoneNumber/" +
            phoneNumber +
            "/" +
            phoneNumberID +
            "/" +
            isRecoveryPhoneNumber +
            "/" +
            userId +
            "/" +
            accountID
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}

const instance = new AdminService();

export default instance;
