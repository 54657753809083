import http from "../../config/http-common";
import AuthService from "./authService";

class AdminAuthService {
  doesEmailAddressExists = (email, invitationCode = "") => {
    return new Promise((resolve, reject) => {
      http
        .post(
          "AdminAccount/DoesEmailAddressExists" , {
            emailAddress: email,
            invitationCode: invitationCode,
          }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  async adminSignIn(emailAddress, isOTP, password) {
    return await new Promise((resolve, reject) => {
      http
        .post(
          "AdminAccount/SignIn", {
            emailAddress: emailAddress,
            isOTP: isOTP,
            password: password,
          }
        )
        .then((response) => {
          if (response.data) {
            //resolve(response.data);
            if (response?.data?.data) {
              localStorage.setItem("authenticated", true);
              AuthService.setSession(response.data.data?.token);
              AuthService.setUser(response.data.data);
              AuthService.isAuthenticated();
              resolve(response.data);
            } else {
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        });
    });
  }

  signInWithOTP = (email, isForgotPassword = false) => {
    return new Promise((resolve, reject) => {
      http
        .post("AdminAccount/SignInWithOTP", {
          emailAddress: email,
          isForgotPassword: isForgotPassword,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  reSendOTP = (email, isSignIn, isForgotPassword = false) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminAccount/ReSendOTP/" +
            email +
            "/" +
            isSignIn +
            "/" +
            isForgotPassword
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  getInvitation = (invitationCode) => {
    return new Promise((resolve, reject) => {
      http
        .get("AdminAccount/GetInvitation/" + invitationCode)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  memberSignUp = (emailAddress, roleID, password) => {
    return new Promise((resolve, reject) => {
      http
        .post("AdminAccount/MemberSignUp", {
          emailAddress: emailAddress,
          roleID: roleID,
          password: password,
        })
        .then((response) => {
          if (response.data) {
            if (response?.data?.data) {
              localStorage.setItem("authenticated", true);
              AuthService.setSession(response.data.data?.token);
              AuthService.setUser(response.data.data);
              resolve(response.data);
            } else {
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        });
    });
  };

  async signIn(emailAddress, isOTP, password) {
    return await new Promise((resolve, reject) => {
      http
        .post("AdminAccount/SignIn/", {
          emailAddress: emailAddress,
          isOTP: isOTP,
          password: password,
        })
        .then((response) => {
          if (response.data) {
            //resolve(response.data);
            if (response?.data?.data) {
              localStorage.setItem("authenticated", true);
              AuthService.setSession(response.data.data?.token);
              AuthService.setUser(response.data.data);
              resolve(response.data);
            } else {
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        });
    });
  }

  verifyForgotPassword = (emailAddress, isEmail, password) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          "AdminAccount/VerifyForgotPassword/" +
            emailAddress +
            "/" +
            isEmail +
            "/" +
            password
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  onForgotNewPassword = (emailAddress, isEmail, password) => {
    return new Promise((resolve, reject) => {
      http
        .post("AdminAccount/ForgotNewPassword/", {
          emailAddress: emailAddress,
          isEmail: isEmail,
          password: password,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
}
const instance = new AdminAuthService();

export default instance;
