import React, { useState, useEffect } from "react";
import WelcomeMessageService from "../services/welcomeMessageService";
import UserService from "../services/userService";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";
import "../../style/contents.scss";

function Dashboard() {
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });

  function onGetWelcomeMessage() {
    setLoading(true);
    WelcomeMessageService.getWelcomeMessage()
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEditorData(response.data?.message);
            setTimeout(() => {
              const buttons = document.getElementsByClassName("cta-button");
            for (let button of buttons) {
              button.addEventListener("click", onUpdateTaxClick);
            }
            }, 500);            
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  useEffect(() => {
    onGetWelcomeMessage();
    // Use getElementsByClassName to add event listeners
    if (editorData) {
      return () => {
        const buttons = document.getElementsByClassName("cta-button");
        // Cleanup event listeners on unmount
        for (let button of buttons) {
          button.removeEventListener("click", onUpdateTaxClick);
        }
      };
    }
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function onUpdateTaxClick(event) {
    var url = event.target.getAttribute('data-url');
    UserService.updateTaxReturnClick(0)
    .then((response) => {
      showAppMessage(response);
      window.open(url, '_blank');
    })
    .catch((_errors) => {
      window.open(url, '_blank');
      setLoading(false);
      showAppMessage(_errors);
    });
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="p-2 md:p-4 lg:p-6 h-[calc(100vh_-_60px)] lg:h-[calc(100vh_-_80px)] overflow-auto">
      {loading && (
        <div className="flex items-center justify-center w-full my-4">
          <FadeLoader
            color="#000000"
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="mx-auto w-8"
          />
        </div>
      )}
      <div
        className="w-full xl:w-[90%] sc1540:w-[80%] mx-auto p-2 md:p-3 lg:p-4 bg-yellow-50 rounded ck-editor-msg"
        dangerouslySetInnerHTML={{ __html: editorData }}
      />
    </div>
  );
}
export default Dashboard;
