import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import WelcomeMessageService from "../../services/welcomeMessageService";
import constants from "../../../helpers/constants";
import AuthService from "../../../auth/services/authService";
import { useNavigate } from "react-router-dom";

function WelcomeMessage() {
  const userDetail = AuthService.getUser();
  const navigate = useNavigate();
  const roles = constants?.roles();
  const [permission, setPermission] = useState(
    userDetail?.permissionIds
      ? userDetail?.permissionIds.split(",").map(Number)
      : []
  );
  const [messageData, setMessageData] = useState({});
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });

  function onGetWelcomeMessage() {
    WelcomeMessageService.getWelcomeMessage()
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setMessageData(response.data);
            if (window.CKEDITOR.instances[editorRef.current.id]) {
              window.CKEDITOR.instances[editorRef.current.id].setData(
                response.data?.message
              );
            }
          }
        }
      })
      .catch((_errors) => {
        showAppMessage(_errors);
      });
  }

  function onAddUpdateWelcomeMessage() {
    setLoading(true);
    WelcomeMessageService.addUpdateWelcomeMessage(
      messageData?.messageId,
      window.CKEDITOR.instances[editorRef.current.id].getData()
    )
      .then((response) => {
        setLoading(false);
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            onGetWelcomeMessage();
          }
        }
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  useEffect(() => {
    if (
      !permission.includes(constants.welcomeMessageView) &&
      userDetail?.roleId !== roles.superAdmin
    ) {
      navigate("/404");
    } else {
      onGetWelcomeMessage();
      if (window.CKEDITOR && editorRef.current) {
        // Initialize CKEditor on the textarea with source button in the toolbar
        window.CKEDITOR.replace(editorRef.current, {
          extraPlugins: "sourcearea",
          allowedContent: true,
          toolbar: [
            {
              name: "document",
              items: ["-", "Save", "NewPage", "Preview", "-", "Templates"],
            },
            { name: "editing", items: ["Find", "Replace", "-", "SelectAll"] },
            {
              name: "forms",
              items: [
                "Form",
                "Checkbox",
                "Radio",
                "TextField",
                "Textarea",
                "Select",
                "Button",
                "ImageButton",
                "HiddenField",
              ],
            },
            {
              name: "basicstyles",
              items: [
                "Bold",
                "Italic",
                "Underline",
                "Strike",
                "-",
                "Subscript",
                "Superscript",
                "-",
                "RemoveFormat",
              ],
            },
            {
              name: "paragraph",
              items: [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "-",
                "Blockquote",
                "-",
                "JustifyLeft",
                "JustifyCenter",
                "JustifyRight",
                "JustifyBlock",
                "-",
                "BidiLtr",
                "BidiRtl",
              ],
            },
            { name: "links", items: ["Link", "Unlink", "Anchor"] },
            {
              name: "insert",
              items: [
                "Image",
                "Table",
                "HorizontalRule",
                "Smiley",
                "SpecialChar",
                "PageBreak",
              ],
            },
            { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
            { name: "colors", items: ["TextColor", "BGColor"] },
            { name: "tools", items: ["Maximize", "ShowBlocks"] },
            { name: "document", items: ["Source"] },
          ],
        });
      }

      // Cleanup CKEditor instance on component unmount
      return () => {
        if (window.CKEDITOR.instances && window.CKEDITOR.instances.editor1) {
          window.CKEDITOR.instances.editor1.destroy(true);
        }
      };
    }
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  return (
    <div className="p-6">
      <h2 className="font-medium text-primaryLime-500 mb-4">Welcome Message</h2>
      <div className="h-[calc(100vh_-_180px)] overflow-auto">
        <div className="w-full md:w-[75%] lg:w-[50%]">
          <textarea ref={editorRef} name="editor1" id="editor1"></textarea>
        </div>
        {permission.includes(constants.welcomeMessageCreate) ||
        permission.includes(constants.welcomeMessageEdit) ||
        userDetail?.roleId === roles.superAdmin ? (
          <div className="relative">
            <div className="flex item-center gap-6 w-full mt-4">
              <Button
                variant="contained"
                color="secondary"
                className="primary-btn btn-small bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out py-4"
                aria-label="Sign in"
                disabled={loading}
                type="submit"
                size="large"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  onAddUpdateWelcomeMessage();
                }}
              >
                {(loading && (
                  <PulseLoader
                    color="#000000"
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )) ||
                  "Save"}
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default WelcomeMessage;
