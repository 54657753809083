import { Link } from "react-router-dom";

function TermsConditions() {
    return(
        <div className="bg-gray-50">
            <div class="container mx-auto">
            <h1 className="p-4 sm:p-6 md:p-8 lg:p-12 text-center">Terms and Conditions</h1>

            <div className="bg-white p-4 sm:p-6 md:p-8 lg:p-12 rounded-12">
                <div>
                    <p>Welcome to <strong>Your Website Name</strong>. By accessing or using this website, you agree to be bound by these Terms and Conditions.</p>

                    <h2>1. Introduction</h2>
                    <p>These Terms and Conditions govern your use of our website and services. Please read them carefully.</p>

                    <h2>2. Acceptance of Terms</h2>
                    <p>By accessing or using our website, you agree to these Terms. If you do not agree, you should not use the site.</p>

                    <h2>3. User Responsibilities</h2>
                    <p>As a user of our website, you agree to:</p>
                    <ul>
                        <li>Provide accurate and up-to-date information when required.</li>
                        <li>Use the website for lawful purposes only.</li>
                        <li>Not engage in any activity that may harm the website or other users.</li>
                    </ul>

                    <h2>4. Intellectual Property</h2>
                    <p>The content provided on this website is owned by <strong>Your Company Name</strong> or its licensors and is protected by copyright law.</p>

                    <h2>5. Privacy Policy</h2>
                    <p>We value your privacy. Please refer to our <a href="#">Privacy Policy</a> for information on how we collect and use your data.</p>

                    <h2>6. Termination</h2>
                    <p>We reserve the right to suspend or terminate your access to the website if you violate any of these Terms and Conditions.</p>

                    <h2>7. Limitation of Liability</h2>
                    <p>We are not liable for any damages resulting from your use or inability to use the website or its content.</p>

                    <h2>8. Governing Law</h2>
                    <p>These Terms and Conditions are governed by the laws of <strong>Your Jurisdiction</strong>. Any disputes will be resolved in the appropriate courts of <strong>Your Jurisdiction</strong>.</p>

                    <h2>9. Changes to the Terms</h2>
                    <p>We may update these Terms from time to time. Any changes will be posted on this page, and by continuing to use the website, you agree to the updated terms.</p>

                    <h2>10. Contact Us</h2>
                    <p>If you have any questions regarding these Terms and Conditions, please contact us at <a href="https://taxpert.com/">taxpert.com</a>.</p>
                </div>
                <div className="mt-6 text-center">
                    <Link className="primary-btn btn-small" to="/dashboard" >Back to Dashboard</Link>
                </div>
            </div>
        </div>
    </div>
    );
}
export default TermsConditions;