class Constants {
  ResendOTPSeconds = 60;
  ResendOTPCount = 1000;
  ResendOTPPreference = "";

  userView = 1;
  userCreate = 2;
  userEdit = 3;
  userDelete = 4;

  roleView = 5;
  roleCreate = 6;
  roleEdit = 7;
  roleDelete = 8;

  invitationView = 9;
  invitationCreate = 10;
  invitationEdit = 11;
  invitationDelete = 12;

  adminUserView = 13;
  adminUserCreate = 14;
  adminUserEdit = 15;
  adminUserDelete = 16;

  appEmailView = 17;
  appEmailCreate = 18;
  appEmailEdit = 19;
  appEmailDelete = 20;

  appEmailView = 21;
  appEmailCreate = 22;
  appEmailEdit = 23;
  appEmailDelete = 24;

  welcomeMessageView = 25;
  welcomeMessageCreate = 26;
  welcomeMessageEdit = 27;
  welcomeMessageDelete = 28;

  superAdmin = "SuperAdmin";
  admin = "Admin";
  user = "User";

  signInSteps = () => {
    return {
      signIn: 0,
      signInNext: 1,
      signInWithOTP: 2,
      signInWithPassword: 3,
      signInWithRecovery: 4,
      signInWithRecoveryOTP: 5,
    };
  };

  forgotPasswordSteps = () => {
    return {
      forgotPassword: 0,
      forgotPasswordOTP: 1,
      newPassword: 2,
    };
  };

  signUpSteps = () => {
    return {
      signUp: 0,
      signUpWithOTP: 1,
      signUpSuccess: 2,
    };
  };

  signInSkipAttempts = () => {
    return {
      skipAttemptEnd: 2,
    };
  };

  messageStatusCodes = () => {
    return {
      success: 200,
      badRequest: 400,
      internalServerError: 500,
    };
  };

  settingTabSteps = () => {
    return {
      profile: 0,
      accountRecovery: 1,
    };
  };

  sidebarMenu = () => {
    return {
      dashboard: 0,
      settings: 1,
      users: 2,
      roles: 3,
      adminUsers: 4,
      adminDashboard: 5,
      taxReturn: 6,
      support: 7,
      welcomeMessage: 8,
      appEmails: 9,
    };
  };

  signInPreferenceTypeIDs = () => {
    return {
      signUp: 0,
      signInWihOtp: 1,
      signInWihPassword: 2,
      signInWihPasswordAndNoPreference: 3,
    };
  };

  roles = () => {
    return {
      superAdmin: 1,
      admin: 2,
      user: 3,
    };
  };

  resendOTP = () => {
    if (this.ResendOTPCount > 0) {
      const timerId = setInterval(() => {
        return (endCount) => endCount - 1;
      }, this.ResendOTPCount);
      return () => clearInterval(timerId);
    }
  };

  userPermissions = () => {
    return [
      {
        name: "Users",
        view: this.userView,
        create: this.userCreate,
        edit: this.userEdit,
        delete: this.userDelete,
      },
      {
        name: "Roles",
        view: this.roleView,
        create: this.roleCreate,
        edit: this.roleEdit,
        delete: this.roleDelete,
      },
      {
        name: "Invitations",
        view: this.invitationView,
        create: this.invitationCreate,
        edit: this.invitationEdit,
        delete: this.invitationDelete,
      },
      {
        name: "Admin Users",
        view: this.adminUserView,
        create: this.adminUserCreate,
        edit: this.adminUserEdit,
        delete: this.adminUserDelete,
      },
      {
        name: "App Emails",
        view: this.appEmailView,
        create: this.appEmailCreate,
        edit: this.appEmailEdit,
        delete: this.appEmailDelete,
      },
      {
        name: "Welcome Message",
        view: this.welcomeMessageView,
        create: this.welcomeMessageCreate,
        edit: this.welcomeMessageEdit,
        delete: this.welcomeMessageDelete,
      },
    ];
  };
}
const instance = new Constants();
export default instance;
