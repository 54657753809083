import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AuthService from "../../auth/services/authService";
import UserService from "../services/userService";
import FadeLoader from "react-spinners/FadeLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dateFormat, { masks } from "dateformat";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OtpInput from "react-otp-input";
import Button from "@mui/material/Button";
import constants from "../../helpers/constants";
import AppMessage from "../../main/app-message/message";
import PulseLoader from "react-spinners/PulseLoader";
import { useForm } from "react-hook-form";
import TaxReturnService from "../services/taxReturnService";

function TaxReturns() {
  const userDetail = AuthService.getUser();
  const [taxPayerList, setTaxPayerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [isView, setIsView] = useState(false);
  const [taxYear, setTaxYear] = useState(2023);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [showPriorMessage, setShowPriorMessage] = useState(false);
  const [resendOTPSeconds, setResendOTPSeconds] = useState(
    constants.ResendOTPSeconds
  );
  const fileSteps = constants?.signUpSteps();
  const [fileStep, setFileStep] = useState(fileSteps.signUpWithOTP);
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const [taxReturnFileId, setTaxReturnFileId] = useState(0);
  var defaultValues = "";
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: null,
  });

  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  function onGetTaxPayers(taxYear) {
    setLoading(true);
    UserService.getTaxpayers(taxYear, userDetail?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setTaxPayerList(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function handleOnViewOrDownloadFile() {
    setLoading(true);
    UserService.getFileOTP(userDetail?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmail(response.data);
            setOtp("");
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
            setShowModal(true);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onViewFile(file) {
    setIsView(true);
    setTaxReturnFileId(file?.taxreturnFileId);
    setFileName(file?.logicalName);
    var fileDate = localStorage.getItem("fileDate");
    if (fileDate != null) {
      var minutes = getMinutesBetweenDates(new Date(fileDate), new Date());
      if (minutes > 30) {
        handleOnViewOrDownloadFile();
      } else {
        setTaxReturnFileView(file?.taxreturnFileId, true);
      }
    } else {
      handleOnViewOrDownloadFile();
    }
  }

  function onDownloadFile(file) {
    setTaxReturnFileId(file?.taxreturnFileId);
    setIsView(false);
    setFileName(file?.logicalName);
    var fileDate = localStorage.getItem("fileDate");
    if (fileDate != null) {
      var minutes = getMinutesBetweenDates(new Date(fileDate), new Date());
      if (minutes > 30) {
        handleOnViewOrDownloadFile();
      } else {
        setTaxReturnFileView(file?.taxreturnFileId, false, file?.logicalName);
      }
    } else {
      handleOnViewOrDownloadFile();
    }
  }

  const handleOnDownload = async (fileUrl, taxFileName) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("File not found or error downloading");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = taxFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  function onOtpSubmit() {
    setLoading(true);
    UserService.verifyFileVerificationCode(otp, userDetail?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            localStorage.setItem("fileDate", new Date());
            setShowModal(false);
            setTaxReturnFileView(taxReturnFileId, isView);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function getMinutesBetweenDates(startDate, endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    return Math.round(diff / 60000);
  }

  function onReSendOTP() {
    setLoading(true);
    UserService.reSendFileOTP(userDetail?.userId)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setEmail(response.data);
            setResendOTPSeconds(constants.ResendOTPSeconds);
            showResendOTPSeconds();
            showHideOTPMessage();
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function setTaxReturnFileView(taxreturnFileId, isView, taxFileName = "") {
    if (taxreturnFileId) {
      TaxReturnService.getTaxReturnFile(taxreturnFileId)
        .then((response) => {
          if (response) {
            setFileUrl(URL.createObjectURL(response));
            if (isView) {
              setShowDrawer(true);
            } else {
              handleOnDownload(
                URL.createObjectURL(response),
                fileName ? fileName : taxFileName
              );
            }
          } else {
            response.message = "File not found";
            response.statusCode = 400;
            showAppMessage(response);
          }
        })
        .catch((_errors) => {
          showAppMessage(_errors);
        });
    }
  }

  useEffect(() => {
    onGetTaxPayers(taxYear);
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }

  function showHideOTPMessage(response) {
    setShowOtpMessage(true);
    setTimeout(() => {
      setShowOtpMessage(false);
    }, 3000);
  }

  function showResendOTPSeconds() {
    var intervalId = setInterval(() => {
      setResendOTPSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return "00";
        }
        return prev < 11 ? `0${prev - 1}` : prev - 1;
      });
    }, constants.ResendOTPCount);
  }

  return (
    <div className="h-[calc(100vh_-_80px)] overflow-hidden">
      <div className="h-full flex flex-col">
        <h2 className="font-medium text-primaryLime-500 pt-6 px-6">
          Tax Return Files by Tax Year
        </h2>
        <div className="relative flex alig-items mt-2 mb-3 px-6">
          <label className="flex items-center mr-4">Tax Year</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-32"
            value={taxYear}
            onChange={(event) => {
              setTaxYear(event?.target?.value);
              if (event?.target?.value === "Prior") {
                setTaxPayerList([]);
                setShowPriorMessage(true);
              } else {
                setShowPriorMessage(false);
                onGetTaxPayers(event?.target?.value);
              }
            }}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
            <MenuItem value="2020">2020</MenuItem>
            <MenuItem value="Prior">2019 and Prior</MenuItem>
          </Select>
        </div>

        <div className="flex items-center justify-center flex-col">
          {loading && (
            <div className="flex items-center justify-center w-full mb-4">
              <FadeLoader
                color="#000000"
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="mx-auto w-8"
              />
            </div>
          )}
          {(taxPayerList.length > 0 && (
            <div className="overflow-auto h-[calc(100vh_-_235px)] w-full px-3">
              <div className="w-full home-cards flex flex-wrap">
                {taxPayerList.map((tax) => (
                  <div className="w-full xl:w-1/2 w-full xl:max-w-1/2 p-3">
                    <Card className="h-full">
                      <CardContent>
                        <div className="flex justify-between w-full mb-3">
                          <div>
                            <Typography variant="h5" component="div">
                              TaxPayer
                            </Typography>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              {tax?.taxpayer?.firstName +
                                " " +
                                tax?.taxpayer?.lastName}
                            </Typography>
                          </div>
                          <div className="min-w-28">
                            <Typography variant="h5" component="div">
                              SSN
                            </Typography>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              {tax?.taxpayer?.ssn}
                            </Typography>
                          </div>
                        </div>
                        {tax?.taxReturns.length > 0 && (
                          <div className="w-full mb-2 card-inner-table">
                            <h3>Tax Returns</h3>
                            <TableContainer component={Paper} className="mt-2">
                              <Table
                                sx={{ minWidth: 400 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {tax?.taxReturns.map((row) => (
                                    <TableRow
                                      key={row.taxreturnID}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row?.returnType &&
                                        row?.returnType !== "Fed"
                                          ? row?.returnType + " State"
                                          : row?.returnType
                                          ? row?.returnType
                                          : "Fed"}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {row.returnStatus === "R"
                                          ? "Rejected"
                                          : row.returnStatus === "A"
                                          ? "Accepted"
                                          : row.returnStatus === "SNTF"
                                          ? "Draft"
                                          : ""}
                                        <br />
                                        {row?.returnStatus === "R" ? (
                                          <small>
                                            Rejection Code: {row?.rejectionCode}{" "}
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {row?.efileDate
                                          ? dateFormat(
                                              row?.efileDate,
                                              "mm/dd/yyyy"
                                            )
                                          : ""}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        )}
                        {tax?.taxReturnFiles.length > 0 && (
                          <div className="mt-2">
                            <h3>PDF File</h3>
                            <div className="w-full flex gap-3 flex-wrap">
                              {tax?.taxReturnFiles.map((file) => (
                                <div className="flex w-full lg:w-fit border rounded-12 p-3 items-center justify-between mt-2">
                                  <div className="flex items-center mr-3">
                                    <div className="mr-2">
                                      <i class="fi fi-rr-file-pdf text-2xl flex"></i>
                                    </div>
                                    <div>{file?.logicalName}</div>
                                  </div>
                                  <div className="flex items-center">
                                    <a
                                      class="text-primaryLime-500 text-lg mr-2"
                                      onClick={() => {
                                        onViewFile(file);
                                      }}
                                    >
                                      <Tooltip title="View">
                                        <div>
                                          <i class="fi fi-rr-eye flex"></i>
                                        </div>
                                      </Tooltip>
                                    </a>{" "}
                                    <a
                                      class="text-primaryLime-500 text-lg"
                                      onClick={() => {
                                        onDownloadFile(file);
                                      }}
                                    >
                                      <Tooltip title="Download">
                                        <div>
                                          <i class="fi fi-rr-cloud-download-alt flex"></i>
                                        </div>
                                      </Tooltip>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          )) ||
            (taxPayerList.length === 0 && !loading && !showPriorMessage && (
              <div className="flex items-center justify-center text-center w-full">
                No data found
              </div>
            )) ||
            (showPriorMessage && (
              <div>
                For 2019 or earlier tax years, please{" "}
                <a
                  href={process.env.REACT_APP_SUPPORTURL}
                  className="text-primaryLime-500"
                >
                  contact eFile support
                </a>
                .
              </div>
            ))}
        </div>
        <Drawer
          open={showDrawer}
          anchor={"right"}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' ) {
              return false;
            }
          }}
        >
          <div className="flex justify-between items-center px-4 py-2">
            <h3 className="font-medium text-primaryLime-500">{fileName}</h3>
            <a
              className=""
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              <Tooltip title="Close">
                <div>
                  <i class="fi fi-rr-circle-xmark text-xl flex"></i>
                </div>
              </Tooltip>
            </a>
          </div>
          <iframe
            src={fileUrl}
            width="100%"
            height="600px"
            title="PDF Viewer"
          ></iframe>
        </Drawer>
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' ) {
              return false;
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {fileStep === fileSteps.signUpWithOTP && (
            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full relative"
              onSubmit={handleSubmit(onOtpSubmit)}
            >
              <a
                className="bg-transparent border-0 text-black absolute top-2 md:top-4 lg:top-6 right-2 md:right-4 lg:right-6 text-xl"
                onClick={() => setShowModal(false)}
              >
                <i className="fi fi-rr-circle-xmark"></i>{" "}
              </a>
              <DialogTitle
                id="alert-dialog-title"
                class="font-familjen text-2xl text-center px-4 sm:px-6 md:px-8 lg:px-[50px] pt-6 sm:pt-8 md:pt-12 lg:pt-[50px] pb-3.5 text-[32px]"
              >
                Confirm OTP
              </DialogTitle>
              <DialogContent className="dialog-content">
                <DialogContentText id="alert-dialog-description">
                  <p className="text-center text-black">
                    Enter the OTP sent on{" "}
                    <span className="font-semibold">{email}</span>
                  </p>
                  <div className="mt-[50px]">
                    <div>
                      <div className="relative">
                        <label>Enter OTP</label>
                        <div className="otp-input mt-2">
                          <OtpInput
                            className="w-full p-4"
                            inputStyle="inputStyle"
                            inputType="number"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            placeholder="------"
                            shouldAutoFocus={true}
                            required
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <div className="absolute tp-message">
                          <AppMessage
                            status={appStatus}
                            message={appMessage}
                            vertical="top"
                            horizontal="center"
                            isOpen={appOpen}
                            timeOut={100}
                          ></AppMessage>
                        </div>
                      </div>
                      <div class="mt-4 text-sm text-black">
                        <p className="text-right">
                          Didn't get OTP?{" "}
                          <a
                            disabled={loading || resendOTPSeconds > 0}
                            href="javascript:void(0);"
                            onClick={() => onReSendOTP()}
                            className="text-primaryLime-500"
                          >
                            Resend OTP{" "}
                          </a>
                          {resendOTPSeconds > 0 && (
                            <span className="text-primaryLime-500">
                              (00:{resendOTPSeconds}
                              {constants.ResendOTPPreference})
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-actions">
                <div className="px-4 pb-4 w-full">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-full primary-btn bg-primary-500 hover:bg-primary-600 rounded-xl transition duration-300 ease-in-out"
                    aria-label="Sign in"
                    disabled={(otp.length !== 6 && true) || false}
                    type="submit"
                    size="large"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {(loading && (
                      <PulseLoader
                        color="#000000"
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )) ||
                      "Save"}
                  </Button>
                </div>
              </DialogActions>
            </form>
          )}
        </Dialog>
      </div>
    </div>
  );
}
export default TaxReturns;
