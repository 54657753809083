import { useState } from "react";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import constants from "../../../helpers/constants";
import UserList from "./user-list";
import AuthService from "../../../auth/services/authService";

function Users() {
  const userDetail = AuthService.getUser();
  const roles = constants?.roles();
  const [permission, setPermission] = useState(
    userDetail?.permissionIds
      ? userDetail?.permissionIds.split(",").map(Number)
      : []
  );
  const settingTabSteps = constants?.settingTabSteps();
  const [tabValue, setTabValue] = useState(
    permission.includes(constants.userView) ||
      userDetail?.roleId === roles.superAdmin
      ? settingTabSteps.profile
      : settingTabSteps.accountRecovery
  );

  return (
    <div className="h-[calc(100vh_-_60px)] lg:h-[calc(100vh_-_80px)] overflow-hidden">
      <div className="h-full p-6 flex flex-col">
      {permission.includes(constants.userView) ||
            userDetail?.roleId === roles.superAdmin ? (
              <UserList />
            ) : (
              <></>
            )}
        {/* <TabContext value={tabValue} className="p-0">
          <TabList
            className="bg-gray-50 setting-page-tabs"
            onChange={(e, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="lab API tabs example"
          >
            {permission.includes(constants.userView) ||
            userDetail?.roleId === roles.superAdmin ? (
              <Tab label="Users" value={settingTabSteps.profile} />
            ) : (
              <></>
            )}
          </TabList>
          <TabPanel value={settingTabSteps.profile} className="data-table">
            {permission.includes(constants.userView) ||
            userDetail?.roleId === roles.superAdmin ? (
              <UserList />
            ) : (
              <></>
            )}
          </TabPanel>
        </TabContext> */}
      </div>
    </div>
  );
}
export default Users;
