import React, { useState, useEffect } from "react";
import AdminService from "../../services/adminService";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FadeLoader from "react-spinners/FadeLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dateFormat, { masks } from "dateformat";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import { useParams, useNavigate } from "react-router-dom";

function Detail() {
  const params = useParams();
  const navigate = useNavigate();
  const [taxPayerList, setTaxPayerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [taxYear, setTaxYear] = useState(params?.taxYear || 2023);
  const [showPriorMessage, setShowPriorMessage] = useState(false);
  const [state, setState] = useState({
    appOpen: false,
    appStatus: "",
    appMessage: "",
  });
  const { appOpen, appStatus, appMessage } = state;

  function onGetTaxPayers(taxYear) {
    setLoading(true);
    AdminService.getTaxpayers(taxYear, params?.id)
      .then((response) => {
        showAppMessage(response);
        if (response?.statusCode === 200) {
          if (response.data) {
            setTaxPayerList(response.data);
          }
        }
        setLoading(false);
      })
      .catch((_errors) => {
        setLoading(false);
        showAppMessage(_errors);
      });
  }

  function onViewFile(file) {
    setFileUrl(getUrlPath(file.filePath));
    setFileName(file?.logicalName);
    setShowDrawer(true);
  }

  function getUrlPath(filePath) {
    return (
      process.env.REACT_APP_BASEURL + filePath.replace(/\\/g, "/").substring(1)
    );
  }

  const onDownloadFile = async (file) => {
    try {
      if (file?.filePath) {
        var filePath = getUrlPath(file.filePath);
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error("File not found or error downloading");
        }
        const blob = await response.blob();
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = file?.logicalName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    if (params?.id && params?.taxYear) {
      onGetTaxPayers(taxYear);
    } else {
      navigate("/404");
    }
  }, []);

  function showAppMessage(response) {
    setState({
      ...state,
      appOpen: true,
      appMessage: response?.message,
      appStatus: response?.statusCode,
    });
    hideAppMessage();
  }

  function hideAppMessage() {
    setTimeout(() => {
      setState({
        ...state,
        appOpen: false,
        appMessage: "",
        appStatus: 0,
      });
    }, 3000);
  }
  return (
    <div className="h-[calc(100vh_-_80px)] overflow-hidden">
      <div className="h-full flex flex-col">
        <h2 className="font-medium text-primaryLime-500 pt-6 px-6">
          Tax Return Files by Tax Year
        </h2>
        <div className="relative flex alig-items mt-2 mb-3 px-6">
          <label className="flex items-center mr-4">Tax Year</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-32"
            value={taxYear}
            onChange={(event) => {
              setTaxYear(event?.target?.value);
              if (event?.target?.value === "Prior") {
                setTaxPayerList([]);
                setShowPriorMessage(true);
              } else {
                setShowPriorMessage(false);
                onGetTaxPayers(event?.target?.value);
              }
            }}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
            <MenuItem value="2020">2020</MenuItem>
            <MenuItem value="Prior">2019 and Prior</MenuItem>
          </Select>
        </div>

        <div className="flex items-center justify-center flex-col">
          {loading && (
            <div className="flex items-center justify-center w-full mb-4">
              <FadeLoader
                color="#000000"
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="mx-auto w-8"
              />
            </div>
          )}
          {(taxPayerList.length > 0 && (
            <div className="overflow-auto h-[calc(100vh_-_235px)] w-full px-3">
              <div className="w-full home-cards flex flex-wrap">
                {taxPayerList.map((tax) => (
                  <div className="w-full xl:w-1/2 w-full xl:max-w-1/2 p-3">
                    <Card className="h-full">
                      <CardContent>
                        <div className="flex justify-between w-full mb-3">
                          <div>
                            <Typography variant="h5" component="div">
                              TaxPayer
                            </Typography>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              {tax?.taxpayer?.firstName +
                                " " +
                                tax?.taxpayer?.lastName}
                            </Typography>
                          </div>
                          <div className="min-w-28">
                            <Typography variant="h5" component="div">
                              SSN
                            </Typography>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              {tax?.taxpayer?.ssn}
                            </Typography>
                          </div>
                        </div>
                        {tax?.taxReturns.length > 0 && (
                          <div className="w-full mb-2 card-inner-table">
                            <h3>Tax Returns</h3>
                            <TableContainer component={Paper} className="mt-2">
                              <Table
                                sx={{ minWidth: 400 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {tax?.taxReturns.map((row) => (
                                    <TableRow
                                      key={row.taxreturnID}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row?.returnType &&
                                        row?.returnType !== "Fed"
                                          ? row?.returnType + " State"
                                          : row?.returnType
                                          ? row?.returnType
                                          : "Fed"}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {row.returnStatus === "R"
                                          ? "Rejected"
                                          : row.returnStatus === "A"
                                          ? "Accepted"
                                          : row.returnStatus === "SNTF"
                                          ? "Draft"
                                          : ""}
                                        <br />
                                        {row?.returnStatus === "R" ? (
                                          <small>
                                            Rejection Code: {row?.rejectionCode}{" "}
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {row?.efileDate
                                          ? dateFormat(
                                              row?.efileDate,
                                              "mm/dd/yyyy"
                                            )
                                          : ""}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        )}
                        {tax?.taxReturnFiles.length > 0 && (
                          <div className="mt-2">
                            <h3>PDF File</h3>
                            <div className="w-full flex gap-3 flex-wrap">
                              {tax?.taxReturnFiles.map((file) => (
                                <div className="flex w-full lg:w-fit border rounded-12 p-3 items-center justify-between mt-2">
                                  <div className="flex items-center mr-3">
                                    <div className="mr-2">
                                      <i class="fi fi-rr-file-pdf text-2xl flex"></i>
                                    </div>
                                    <div>{file?.logicalName}</div>
                                  </div>
                                  <div className="flex items-center">
                                    <a
                                      class="text-primaryLime-500 text-lg mr-2"
                                      onClick={() => {
                                        onViewFile(file);
                                      }}
                                    >
                                      <Tooltip title="View">
                                        <div>
                                          <i class="fi fi-rr-eye flex"></i>
                                        </div>
                                      </Tooltip>
                                    </a>{" "}
                                    <a
                                      class="text-primaryLime-500 text-lg"
                                      onClick={() => {
                                        onDownloadFile(file);
                                      }}
                                    >
                                      <Tooltip title="Download">
                                        <div>
                                          <i class="fi fi-rr-cloud-download-alt flex"></i>
                                        </div>
                                      </Tooltip>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          )) ||
            (taxPayerList.length === 0 && !loading && !showPriorMessage && (
              <div className="flex items-center justify-center text-center w-full">
                No data found
              </div>
            )) ||
            (showPriorMessage && (
              <div>
                For 2019 or earlier tax years, please{" "}
                <a
                  href={process.env.REACT_APP_SUPPORTURL}
                  className="text-primaryLime-500"
                >
                  contact eFile support
                </a>
                .
              </div>
            ))}
        </div>
        <Drawer
          open={showDrawer}
          anchor={"right"}
          onClose={(event, reason) => {
            if (reason === 'backdropClick' ) {
              return false;
            }
          }}
        >
          <div className="flex justify-between items-center px-4 py-2">
            <h3 className="font-medium text-primaryLime-500">{fileName}</h3>
            <a
              className=""
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              <Tooltip title="Close">
                <div>
                  <i class="fi fi-rr-circle-xmark text-xl flex"></i>
                </div>
              </Tooltip>
            </a>
          </div>
          <iframe
            src={fileUrl}
            width="100%"
            height="600px"
            title="PDF Viewer"
          ></iframe>
        </Drawer>
      </div>
    </div>
  );
}
export default Detail;
