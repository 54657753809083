import { Routes, Route, useNavigate } from "react-router-dom";
import PrivateRoute from "../src/app/components/private-route";
import SignIn from "./app/auth/signin/sign-in";
import SignInAdmin from "./app/auth/admin/signin/sign-in";
import ForgotPassword from "./app/auth/forgot-password/forgot-password";
import ForgotAdminPassword from "./app/auth/admin/signin/forgot-password";
import Dashboard from "./app/main/dashboard/dashboard";
import AdminDashboard from "./app/main/admin/dashboard/dashboard";
import Setting from "./app/main/settings/setting";
import AdminSetting from "./app/main/admin/settings/setting";
import TaxReturn from "./app/main/tax-returns/tax-return";
import Roles from "./app/main/admin/roles/roles";
import Detail from "./app/main/admin/user-details/detail";
import TaxReturnDetail from "./app/main/admin/tax-returns/detail";
import AdminDetail from "./app/main/admin/admin-details/detail";
import WelcomeMessage from "./app/main/admin/welcome-messages/welcome-message";
import { theme } from "./app/config/style.config";
import { ThemeProvider } from "@mui/material/styles";
import TermsConditions from "./app/auth/terms/termsConditions";
import Invitation from "./app/auth/admin/signin/invitation";
import Users from "./app/main/admin/users/users";
import AppEmails from "./app/main/admin/app-emails/app-email-list";
import AppEmailDetail from "./app/main/admin/app-emails/app-email-detail";
import AdminUsers from "./app/main/admin/admin-users/users";
import AuthService from "./app/auth/services/authService";
import constants from "../src/app/helpers/constants";
import ErrorPage from "./app/404";

function App() {
  const navigate = useNavigate();
  const userDetail = AuthService.getUser();
  const roles = constants?.roles();
  if (
    !userDetail &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/sign-in" &&
    window.location.pathname !== "/admin" &&
    window.location.pathname !== "/admin/" &&
    window.location.pathname !== "/admin/sign-in" &&
    window.location.pathname !== "/admin/sign-in/" &&
    window.location.pathname !== "/terms" &&
    !window.location.pathname.includes("/invitation") &&
    !window.location.pathname.includes("/forgot-password") &&
    !window.location.pathname.includes("/admin/forgot-password")
  ) {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 5);
  }
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/admin" element={<SignInAdmin />} />
          <Route path="/admin/" element={<SignInAdmin />} />
          <Route path="/admin/sign-in" element={<SignInAdmin />} />
          <Route path="/admin/sign-in/" element={<SignInAdmin />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/invitation/:id" element={<Invitation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/admin/forgot-password"
            element={<ForgotAdminPassword />}
          />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/404" element={<ErrorPage />} />
          {userDetail?.roleId === roles.admin ||
          userDetail?.roleId === roles.superAdmin ? (
            <Route element={<PrivateRoute />}>
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/admin-users" element={<AdminUsers />} />
              <Route path="/admin/roles" element={<Roles />} />
              <Route path="/admin/setting" element={<AdminSetting />} />
              <Route path="/admin/user-detail/:id" element={<Detail />} />
              <Route path="/admin/admin-detail/:id" element={<AdminDetail />} />
              <Route path="/admin/app-email" element={<AppEmails />} />
              <Route path="/admin/app-email-detail/:id" element={<AppEmailDetail />} />
              <Route path="/admin/app-email-detail" element={<AppEmailDetail />} />
              <Route
                path="/admin/welcome-message"
                element={<WelcomeMessage />}
              />
              <Route
                path="/admin/details/:id/:taxYear"
                element={<TaxReturnDetail />}
              />
            </Route>
          ) : (
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tax-return" element={<TaxReturn />} />
              <Route path="/setting" element={<Setting />} />
            </Route>
          )}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
